.blog-card-container {
  flex: 1;
  width: 255px;
  margin: var(--dl-space-space-unit);
  display: flex;
  max-width: 300px;
  min-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.blog-card-image {
  width: 100%;
  box-shadow: 0 0.25rem 0.375rem -0.0625rem hsl(0deg 0% 8% / 12%), 0 0.125rem 0.25rem -0.0625rem hsl(0deg 0% 8% / 7%);
  object-fit: cover;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius75);
}

.blog-card-text {
  color: var(--dl-color-secondary-500);
  font-size: 1.25rem;
  font-style: normal;
  margin-top: var(--dl-space-space-halfunit);
  font-weight: 600;
  margin-bottom: var(--dl-space-space-halfunit);
}
